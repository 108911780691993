<template>
  <div class="_ggWrap" id="cgfw">

    <div class="_phoneMenu" v-if="!seen">
      <div class="_li" v-for="(item, index) in phoneMenu" :key="index" :class="{ p_active: p_current == index }"
        @click="$router.push({ path: item.path })">
        {{ item.text }}
      </div>

    </div>


    <div class="_ggAuto">

      <div class="_kbgsj">
        <h4></h4>
        <div class="_cont">
          <div class="_left">
            <div class="_time">
              <p>场馆开放时间</p>
              <div class="_p _impact">{{ detail.open_time }}</div>
            </div>

            <div class="_time1">
              <p>周一闭馆,法定节假日除外</p>
              <div class="_p">
                <span class="_span _impact">{{ detail.stop_time }}</span>
                <span>点后停止入馆</span>
              </div>
            </div>
            <div class="_tel">
              <p>联系电话</p>
              <p class="_n _impact">
                <a :href="'tel:' + detail.contact_tel">{{ detail.contact_tel }}</a>
              </p>
            </div>
          </div>

          <div class="_right _com">
            <div class="_img">
              <div class="_code">
                <img :src="$IMG_URL + $store.state.homeData.web_data.oa_qrcode" alt="">
              </div>
              <p>关注公众号预约参观</p>
            </div>
          </div>
        </div>
      </div>


      <div class="_jtlx" id="jtlx">
        <h4></h4>
        <div class="_cont">
          <div class="_left">

            <div class="_map" id="map">

            </div>
            <div class="_lx">
              <div class="_li">
                <div class="_l">
                  <img src="@/assets/images/jtlx-1.png" alt="">
                </div>
                <div class="_r">
                  <div class="_h4">公交路线</div>
                  <div class="_p">{{ detail.bus }}</div>
                </div>
              </div>
              <div class="_li">
                <div class="_l">
                  <img src="@/assets/images/jtlx-2.png" alt="">
                </div>
                <div class="_r">
                  <div class="_h4">机场</div>
                  <div class="_p">{{ detail.airport }}</div>
                </div>
              </div>
              <div class="_li">
                <div class="_l">
                  <img src="@/assets/images/jtlx-3.png" alt="">
                </div>
                <div class="_r">
                  <div class="_h4">免费停车</div>
                  <div class="_p">{{ detail.free_parking }}</div>
                </div>
              </div>
              <div class="_li">
                <div class="_l">
                  <img src="@/assets/images/jtlx-4.png" alt="">
                </div>
                <div class="_r">
                  <div class="_h4">本馆地址</div>
                  <div class="_p">{{ detail.address }}</div>
                </div>
              </div>
            </div>
          </div>


          <div class="_right _com">
            <div class="_img">
              <div class="_code">
                <img :src="$IMG_URL + detail.ocn_qrcode" alt="">
              </div>
              <p>微信扫码 · 一键导航</p>
            </div>

          </div>


        </div>
      </div>

      <div class="_jjfw" id="jjfw">
        <h4></h4>

        <div class="_cont">
          <div class="_left">
            <div class="_html" ref="myHtml" v-html="detail.explaintion_service"></div>
          </div>
          <div class="_right _com">
            <div class="_img">
              <div class="_code">
                <img :src="$IMG_URL + detail.explaintion_service_qrcode" alt="">

              </div>
              <p>微信扫码 · 线上讲解</p>
            </div>
          </div>
        </div>
      </div>
      <div class="_swdt">
        <h4></h4>
        <div class="_cont">
          <div class="_ceng">
            <div :class="{ _btn: true, _active: current == index }" v-for="( item, index ) in  cengList "
              :key="index" @click="changeTab(item, index)">{{ item.name
              }}</div>
          </div>
          <div class="_img">
            <img :src=" seen ? dlSrc : p_dlSrc " alt="">
            <!-- <img :src="seen ? $IMG_URL + detail.nav_3d_1 : p_dlSrc" alt=""> -->

          </div>
        </div>
      </div>


      <div class="_ylxz" id="ylxz">
        <h4></h4>
        <div class="_cont">
          <div class="_html" ref="myHtml" v-html="detail.notice"></div>
        </div>
      </div>



    </div>


    <fixIcon />
    <coment ref="comentRef"></coment>
    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { getDataById } from '../../api/getData'
export default {
  data() {
    return {
      obj: {
        '交通路线': 'jtlx',
        '讲解服务': 'jjfw',
        '游览须知': 'ylxz',
        '参观服务': 'cgfw'

      },
      p_current: 0,
      current: 0,
      detail: {},
      dlSrc: require("@/assets/images/dl.png"),
      p_dlSrc: require("@/assets/images/phone/v.png"),
      cengList: [{
        name: "一层",
        img: require("@/assets/images/dl.png"),
        p_img: require("@/assets/images/phone/v.png"),
      }, {
        name: "二层",
        img: require("@/assets/images/dl1.png"),
        p_img: require("@/assets/images/phone/v1.png"),
      }],
      seen: true,
      phoneMenu: [
        {
          text: "参观服务",
          path: "/exhibit"
        }, {
          text: "精品文创",
          path: "/boutique"
        }, {
          text: "志愿者之家",
          path: "/volHome"
        }
      ]
    }
  },

  async mounted() {
    this.menuSeen()
    this.createMap()
    await this.getData()
    // this.$scrollTop()
    this.jumpId()
  },
  methods: {

    menuSeen() {
      // 屏幕尺寸
      let screenHeight = document.body.clientWidth;
      if (screenHeight > 415) {
        this.seen = true;
      } else {
        this.seen = false;
      }
    },
    jumpId() {
      const title = this.$route.query.tit

      if (title) {
        for (let i in this.obj) {
          // console.log(i)
          if (title == i) {
            this.$scrollToSection(this.obj[i])
          }
        }

      } else {
        this.$scrollTop()
      }

    },
    changeTab(item, index) {
      this.current = index

      if (this.seen) {
        this.dlSrc = item.img

      } else {
        this.p_dlSrc = item.p_img
      }
    },
    createMap() {
      var map = new BMap.Map('map'); // 创建Map实例
      map.centerAndZoom(new BMap.Point(112.495287, 34.704394), 17); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      var marker = new BMap.Marker(new BMap.Point(112.495287, 34.704394));
      // 在地图上添加点标记
      map.addOverlay(marker);
    },

    async getData() {
      const params = {
        cate_id: 18,
        page: 1,
        page_num: 5,
        keywords: "",
        type_id: "",
        times: "",
      }

      await getDataById(params).then(res => {
        console.log(res)
        this.detail = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

::v-deep
{
  .BMap_cpyCtrl
  {
    display: none;
  }

  .anchorBL
  {
    display: none;
  }
}

#map
{
  width: 782px;
  height: 489px;

  border: 14px solid #FFFFFF;
}


._com
{
  // flex: 1;
  // text-align: right;
  text-align: center;

  ._img
  {

    width: 170px;
    height: 200px;
    background: #A5322B;
    border: 1px solid #A5322B;

    ._code
    {
      width: 154px;
      height: 154px;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 5px;

      img
      {

        width: 100%;
        height: 100%;

      }

    }


    p
    {
      color: white;
    }
  }
}

@mixin tit($url)
{
  width: 299px;
  height: 52px;
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

._ggWrap
{
  background: #F8F5EE;
  overflow: hidden;
  // margin-top: 200px;
}

._ggAuto
{
  width: 85%;
  margin: 0 auto;
  margin-top: 200px;


  ._kbgsj
  {
    h4
    {
      @include tit("../../assets/images/h4-6.png");
      margin: 0 auto;
    }

    ._cont
    {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 80px;

      ._left
      {
        display: flex;
        width: 80%;
        justify-content: space-between;

        ._time
        {
          p
          {
            color: #333;
            font-size: 26px;

          }

          ._p
          {
            font-size: 52px;
          }
        }

        ._time1
        {
          p
          {
            color: #333;
            font-size: 26px;
          }

          ._p
          {
            ._span
            {
              font-size: 54px;
              margin-right: 10px;
            }

            span
            {
              color: #000;

            }
          }
        }

        ._tel
        {
          p
          {
            font-size: 26px;

            a
            {
              color: #000;

            }
          }

          ._n
          {
            font-size: 54px;
          }
        }
      }

      ._right
      {
        // flex: 1;
        // text-align: right;
        text-align: center;

        ._img
        {

          width: 170px;
          height: 200px;
          background: #A5322B;
          border: 1px solid #A5322B;

          img
          {


            height: 154px;
            height: 154px;

          }

          p
          {
            color: white;
          }
        }
      }
    }

  }


  ._jtlx
  {
    margin-top: 100px;

    h4
    {
      @include tit("../../assets/images/h4-7.png");
      margin: 0 auto;
    }

    ._cont
    {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      ._left
      {
        display: flex;

        ._lx
        {
          margin-left: 30px;
          width: 540px;

          ._li
          {

            display: flex;
            align-items: center;
            margin-bottom: 42px;

            ._l
            {
              width: 90px;
              height: 90px;
              background: #C0A062;
              opacity: 0.6;
              text-align: center;

              img
              {

                margin-top: 6px;

              }
            }

            ._r
            {
              margin-left: 20px;
              flex: 1;

              ._h4
              {
                color: #333;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 10px;
              }

              ._p
              {
                font-size: 20px;
                //     overflow: hidden;
                //     display: -webkit-box;
                // -webkit-line-clamp: 2;
                // /* 设置最大显示行数 */
                // -webkit-box-orient: vertical;
                // text-overflow: ellipsis;

              }
            }
          }
        }
      }

      ._right
      {}

    }
  }


  ._jjfw
  {
    margin-top: 100px;
    min-height: 400px;

    h4
    {
      @include tit("../../assets/images/h4-8.png");
      margin: 0 auto;
      margin-bottom: 40px;
    }

    ._cont
    {
      display: flex;
      justify-content: space-between;

      ._left
      {
        width: 70%;

        ._html
        {
          width: 100%;
        }
      }
    }
  }

  ._swdt
  {
    h4
    {
      @include tit("../../assets/images/h4-9.png");
      margin: 0 auto;
    }

    ._cont
    {
      margin-top: 40px;
      position: relative;

      ._ceng
      {
        position: absolute;
        top: 100px;
        left: 66px;


        ._btn
        {
          width: 140px;
          height: 48px;
          margin-bottom: 10px;
          border-radius: 8px 8px 8px 8px;
          cursor: pointer;

          border: 1px solid #8A2415;
          text-align: center;
          line-height: 48px;
          font-size: 24px;
          color: #8A2415;
        }

        ._active
        {
          background: #8A2415;
          color: white;
        }
      }

      ._img
      {
        // width: ;
      }


    }
  }

  ._ylxz
  {
    h4
    {
      @include tit("../../assets/images/h4-10.png");
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 30px;
    }

    ._cont
    {
      min-height: 400px;
    }

  }
}


._phoneMenu
{
  // @include respondTo('phone'){

  // }

  display: flex;
  margin-top: 60px;
  width: 100%;
  background-color: #3D4044;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  color: white;

  .p_active
  {
    color: #F9DAA0;
  }
}

._ggAuto
{
  @include respondTo('phone')
  {
    width: 95%;

    margin-top: 20px;


    ._kbgsj
    {
      >h4
      {
        background-image: url("../../assets/images/phone/kbgsj.png");
        width: 173px;
        height: 30px;
      }

      ._cont
      {
        margin-top: 38px;

        ._left
        {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          ._time
          {
            width: 46%;

            p
            {
              font-size: 15px;
            }

            ._p
            {
              font-size: 28px;
            }
          }

          ._time1
          {
            width: 50%;

            p
            {
              font-size: 15px;
            }

            ._p
            {

              ._span
              {
                font-size: 28px;
                margin-right: 0;
              }
            }
          }

          ._tel
          {
            width: 100%;
            border-top: 1px dashed #ccc;
            margin-top: 10px;
            padding-top: 10px;
            display: flex;
            align-items: center;

            p
            {
              font-size: 16px;
              margin-right: 10px;
            }

            ._n
            {
              font-size: 28px;

              a
              {
                color: #000;
              }
            }
          }
        }

        ._right
        {
          display: none;
        }
      }
    }



    ._jtlx
    {
      margin-top: 30px;

      >h4
      {
        background-image: url("../../assets/images/phone/jtlx.png");
        width: 173px;
        height: 30px;
      }

      ._cont
      {
        margin-top: 20px;
        display: block;

        ._left
        {
          display: block;

          #map
          {
            width: 100%;
            height: 239px;
            border: 1px solid #fff;
          }

          ._lx
          {
            width: 100%;
            margin-left: 0;
            margin-top: 15px;

            ._li
            {
              margin-bottom: 16px;

              ._r
              {
                ._p
                {
                  font-size: 16px;
                }
              }
            }
          }
        }

        ._right
        {
          display: none;
        }
      }
    }


    ._jjfw
    {
      margin-top: 32px;
      min-height: 200px;

      >h4
      {
        background-image: url("../../assets/images/phone/jjfw.png");

        width: 173px;
        height: 30px;
        margin-bottom: 30px;
      }

      ._cont
      {
        ._left
        {
          width: 100%;
        }

        ._right
        {
          display: none;
        }
      }
    }

    ._swdt
    {
      margin-top: 32px;

      >h4
      {
        background-image: url("../../assets/images/phone/swdl.png");
        ;
        width: 173px;
        height: 30px;
        margin-bottom: 30px;
      }

      ._cont
      {
        margin-top: 0;

        ._ceng
        {
          // position: initial;
          display: flex;
          top: 46%;
          left: 10%;

          ._btn
          {
            margin-right: 10px;
          }
        }

        ._img
        {
          width: 100%;
          height: 571px;

          img
          {
            width: 100%;
            height: 100%;
            // object-fit: cover;
          }
        }
      }

    }

    ._ylxz
    {
      >h4
      {
        background-image: url("../../assets/images/phone/ylxz.png");
        ;
        width: 173px;
        height: 30px;
        margin-bottom: 30px;
        margin-top: 32px;
      }
    }

  }
}
</style>